<template>
  <div class="container">
    <div class="background">
      <div class="d-flex flex-column align-items-center justify-content-center h-100 content-box">
      <img src="../public/img/ufadragon168-icon-192x192.png" alt="Logo" class="mb-3" />
      <h1>ติดตั้งทางเข้าเล่น</h1>
      <h1>UFADRAGON168</h1>
      <p>เพื่อความสะดวกในการเข้าเล่นและใช้งาน</p>
      <div class="d-flex flex-column align-items-center">
        <button @click="promptAddToHomeScreen" class="btn btn-primary my-2 zoom-in-out"><i class="fas fa-download"></i>
          ติดตั้งแอพ</button>
        <a href="https://bit.ly/3Ryj3SS" class="btn btn-warning my-2"><i class="fas fa-arrow-right"></i> ทางเข้าเล่น</a>
        <a href="https://line.me/R/ti/p/@ufadragon168" class="btn btn-success my-2"><i class="fas fa-user"></i>
          ติดต่อแอดมิน</a>
      </div>
    </div>
    

    </div>

    <!-- Modal -->
    <div class="modal fade" id="iosModal" tabindex="-1" aria-labelledby="iosModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="iosModalLabel">วิธีติดตั้งแอพบน Iphone</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>1. กดปุ่ม <strong>แชร์</strong> ที่ด้านล่างของหน้าเว็บ</p>
            <img src="../public/img/step1.png" alt="step1" class="img-fluid mb-3" />
            <p>2. เลือก <strong>เพิ่มไปยังหน้าจอโฮม</strong></p>
            <img src="../public/img/step2.png" alt="step2" class="img-fluid mb-3" />
            <p>3. กด <strong>เพิ่ม</strong> ที่มุมขวาบน</p>
            <img src="../public/img/ufadragon168-step3.png" alt="step3" class="img-fluid mb-3" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'; // นำเข้า Modal จาก bootstrap

export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  mounted() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // Redirect
      window.location.href = 'https://bit.ly/3Ryj3SS';
    }
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
    });

    window.addEventListener('appinstalled', () => {
      console.log('App installed');
      // Redirect
      window.location.href = 'https://bit.ly/3Ryj3SS';
    });
  },
  methods: {
    isIOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    promptAddToHomeScreen() {
      if (this.isIOS()) {
        // Show modal
        const modal = new Modal(document.getElementById('iosModal')); // ใช้ Modal จาก bootstrap
        modal.show();
        return;
      }

      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },
  },
};
</script>

<style>
/* No custom styles needed if using Bootstrap */
body {
  font-family: 'Kanit', sans-serif !important;
  background-color: #f8f9fa;
}

.container {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.background {
  background-image: url('https://aigcdn.b-cdn.net/BG-Dragon168(763x1280).jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(245, 8, 0);
}

.content-box {
  background: rgba(0, 0, 0, 0.5); /* พื้นหลังสีดำโปร่งแสง 50% */
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.zoom-in-out {
  animation: zoomInOut 0.6s ease-in-out infinite alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>

<!-- Include Bootstrap CSS and JS here -->
